<template>
  <div class="flex items-center h-max">
    <button
      class="p-1 flex justify-center items-center w-10 rounded-md mr-2 cursor-pointer h-[36px]"
      :class="[visibilityScope === ALL ? 'bg-gray-300' : 'bg-gray-200']"
      @click="handleBusinessNoteVisibilityScope"
    >
      <tooltip>
        <template v-if="!isNotePiPModeActivated" #content>
          <p class="font-medium mb-1">
            {{ $t("DEALS.DEAL_NOTES.INTERNAL_NOTE") }}
          </p>
          <p>{{ $t("DEALS.DEAL_NOTES.INTERNAL_NOTE_DESCRIPTION") }}</p>
        </template>
        <template #default>
          <icon-base
            height="28"
            width="28"
            viewBox="0 0 20 14"
            icon="show-pass-no-bg"
            :icon-color="visibilityScopeIconColor"
            class="ml-1"
          />
        </template>
      </tooltip>
    </button>
  </div>
</template>
<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { NOTE_VISIBILITY_SCOPE, UTIL_COLORS } from "@/helpers/constants";
import useNotesStore from "@/stores/notes";
import { storeToRefs } from "pinia";

const props = defineProps({
  visibilityScope: {
    type: Number,
    required: true
  },
  inputsVisible: {
    type: Boolean,
    required: true
  }
});

const { ALL, LENDFLOW } = NOTE_VISIBILITY_SCOPE;
const visibilityScope = ref(props.visibilityScope);
const notesStore = useNotesStore();
const { isNotePiPModeActivated } = storeToRefs(notesStore);

const emit = defineEmits(["changeScope"]);

const visibilityScopeIconColor = computed(() =>
  visibilityScope.value === ALL ? "white" : UTIL_COLORS.PRIMARY
);

const handleBusinessNoteVisibilityScope = () => {
  if (visibilityScope.value === ALL) {
    visibilityScope.value = LENDFLOW;
    emit("changeScope", visibilityScope.value);
    return;
  }
  visibilityScope.value = ALL;

  emit("changeScope", visibilityScope.value);
};

watch(
  () => props.visibilityScope,
  () => {
    visibilityScope.value = props.visibilityScope;
  }
);
</script>
